import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as messageBus from '@wh/message-bus';

const CookieBar = ({ environment, cookieBannerIsNotVisible }) => {
  useEffect(() => {
    // Ensure the WH object and messageBus are available on the global window object
    window.WH = window.WH || {};
    window.WH.messageBus = messageBus;
  }, []);

  const isDevOrNonProd = ['dev', 'nonprod'].includes(environment);
  const envSuffix = isDevOrNonProd ? '-pp1' : '';
  const cookieBannerUrl = `//apps.static-cs.williamhill${envSuffix}.com/overlay-integration/overlay-bundle.js`;

  if (cookieBannerIsNotVisible) {
    return null;
  }

  return (
    <div data-testid="cookie-bar">
      <script src={cookieBannerUrl} data-testid="cookie-bar-script" />
    </div>
  );
};

CookieBar.propTypes = {
  environment: PropTypes.oneOf(['dev', 'nonprod']).isRequired,
  cookieBannerIsNotVisible: PropTypes.bool.isRequired,
};

export default CookieBar;
